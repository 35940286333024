import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import { getRequest } from '../../utility/axiosClient';
import { store } from '../store';

export function* temporaryAction() {
  try {
  } catch (error) {
    yield put({ type: actions.TEMP0RARY_ACTION_SUCCESS });
  }
}

export function* plansPageList() {
  try {
    const response = yield call(() => getRequest('v4/user/plan-page/list'));
    store.dispatch({
      type: actions.GET_PLANS_PAGE_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.GET_PLANS_PAGE_LIST_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.TEMP0RARY_ACTION, temporaryAction),
    takeEvery(actions.GET_PLANS_PAGE_LIST, plansPageList),
  ]);
}
