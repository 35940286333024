import actions from './actions';

const initState = {
  userDetail: '',
  currentTab: ['capture', 'activities'],
  contentWidth: {
    capture: '10%',
    activities: '90%',
    sparks: '0',
    shared: '0',
    settings: '0',
    search: '0',
    home: '0',
    people: '0',
    trash: '0',
  },
  settingsNavigation: 'account-settings',
  settingsOpenedKeys: [],
  planDetail: null,
  loader: false,
};

export default function settingsReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_ACTIVE_TAB_WIDTH: {
      const { contentWidth, currentTab } = action.payload;
      return {
        ...state,
        currentTab,
        contentWidth,
      };
    }
    case actions.SET_SETTINGS_NAVIGATION: {
      const { payload, openedKeys } = action;
      return {
        ...state,
        settingsNavigation: payload,
        settingsOpenedKeys: openedKeys,
      };
    }
    case actions.GET_PLANS_PAGE_LIST: {
      return {
        ...state,
        loader: true,
      };
    }
    case actions.GET_PLANS_PAGE_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        planDetail: action.payload,
      };
    }
    case actions.GET_PLANS_PAGE_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
      };
    }
    default: {
      return state;
    }
  }
}
