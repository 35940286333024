import { addAsyncAction } from 'utility/asyncHelper';
import commonActions from 'redux/common/actions';
import { store } from '../../redux/store';
import { CONTAINER_TYPES } from './constant';

export function moveAPI({
  parentId = null,
  targetId = null,
  activeIds = [],
  extractName = '',
  overDocument = false,
  overFolder = false,
  targetContainer = null,
  isOverIndexSection = false,
  initiateGetAllGroupsList = false,
  hasRootUpdate = false,
  overCategory = false,
  hasParentUpdated = false,
}) {
  const movePayload = {
    parentId,
    payload: {
      target_id: targetId,
      object_ids: activeIds,
    },
    extractName,
    overDocument,
    overFolder,
    targetContainer,
    isOverIndexSection,
    initiateGetAllGroupsList,
    overCategory,
    hasParentUpdated,
  };
  if (hasRootUpdate) {
    movePayload.payload.has_root_update = hasRootUpdate;
  }
  addAsyncAction(commonActions.ASYNC_MOVE_API, movePayload);
}

export function moveCaptureItemToOthersHelper({
  activeIds = [],
  targetId = null,
  extractName = '',
  overDocument = false,
  targetContainer = null,
  isOverIndexSection = null,
}) {
  addAsyncAction(commonActions.ASYNC_MOVE_CAPTURE_ITEM_TO_OTHERS, {
    payload: {
      target_id: targetId,
      object_ids: activeIds,
    },
    extractName,
    overDocument,
    targetContainer,
    isOverIndexSection,
  });
}

export function moveItemsToCapture({
  activeIds = [],
  parentId = null,
  extractName = '',
}) {
  addAsyncAction(commonActions.ASYNC_MOVE_ITEMS_TO_CAPTURE, {
    payload: {
      object_ids: activeIds,
    },
    parentId,
    extractName,
  });
}

export function reorderItems({ objectId = null, oldIndex = 0, newIndex = 0 }) {
  addAsyncAction(commonActions.ASYNC_REORDER, {
    payload: {
      move: oldIndex - newIndex,
    },
    objectId,
  });
}

export function freeSpaceViewAPIHelper({
  activeFreeSpaceItemType = null,
  activeId = null,
  activeObjectType = null,
  activeItemIsURL = null,
  from = null,
  viewType = 'large_grid',
}) {
  let arrayItems = null;
  if (from === CONTAINER_TYPES.JOTS) {
    const { jotsProjectItems } = store.getState().jotsReducer;
    arrayItems = jotsProjectItems;
  } else {
    const { boardDetails } = store.getState().BoardGroups;
    arrayItems = boardDetails;
  }
  const exactedDragItem = arrayItems.find((elem) => elem.id === activeId);
  const { x_coordinate, y_coordinate } = exactedDragItem?.properties;
  store.dispatch({
    type: commonActions.FREE_SPACE_API_LATEST,
    payload: {
      properties: {
        x_coordinate: x_coordinate ? x_coordinate : 0,
        y_coordinate: y_coordinate ? y_coordinate : 0,
        view_type: viewType || 'large_grid',
      },
      type: activeObjectType,
    },
    objectId: activeId,
    isTypeItem: activeFreeSpaceItemType === 'items',
    isUrl: activeItemIsURL,
  });
}

export function freeSpaceItemViewHelper({
  objectId = null,
  viewType = 'large_grid',
  type = null,
}) {
  store.dispatch({
    type: commonActions.FREE_SPACE_API_LATEST,
    payload: {
      properties: {
        view_type: viewType || 'large_grid',
      },
      type,
    },
    objectId: objectId,
  });
}
