import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { rootSaga, createSagaInjector } from './sagas';
import { createBrowserHistory } from 'history';
import rootReducer, { createRootReducer } from './reducers';
const history = createBrowserHistory(),
  sagaMiddleware = createSagaMiddleware(),
  middlewares = [];
middlewares.push(sagaMiddleware);
const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      : compose,
  getEnhancers = () => {
    if (
      ['development', 'testing'].includes(process.env.REACT_APP_ENVIRONMENT)
    ) {
      return composeEnhancers(applyMiddleware(...middlewares));
    } else {
      return applyMiddleware(...middlewares);
    }
  };

const store = createStore(rootReducer, getEnhancers());
// Custom Injection of Saga
store.injectSaga = createSagaInjector(sagaMiddleware.run, rootSaga);
// Custom Injection of Reducers
store.asyncReducers = {};
store.injectReducer = (key, reducer) => {
  store.asyncReducers[key] = reducer;
  store.replaceReducer(createRootReducer(store.asyncReducers));
};

export { store, history };
