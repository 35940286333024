import actions from './actions';

const initState = {
  loading: false,
  plans: null,
  selectedPlanId: null,
  planDetail: null,
  clientPortalUrl: null,
  subscriptionModalVisibility: false,
  subscriptionValidationInfo: null,
};

export default function subscriptionReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_PLANS_LIST: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.GET_PLANS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        plans: action.payload,
      };
    }
    case actions.GET_PLANS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.SELECT_PLAN: {
      return {
        ...state,
        selectedPlanId: action.payload,
      };
    }
    case actions.GET_PLAN_DETAIL_SUCCESS: {
      return {
        ...state,
        planDetail: action.payload,
      };
    }
    case actions.GET_CLIENT_PORTAL_URL_SUCCESS: {
      return {
        ...state,
        clientPortalUrl: action.payload,
      };
    }
    case actions.SUBSCRIPTION_VALIDATION_MODAL: {
      return {
        ...state,
        subscriptionModalVisibility: action.payload.visibility,
        subscriptionValidationInfo: action.payload.info || null,
      };
    }
    default: {
      return state;
    }
  }
}
