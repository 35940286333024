export const CANCEL_SUBSCRIPTION_MODAL = 'cancel_subscription_modal';
export const OFFER_MODAL = 'offer_modal';
export const SEVEN_DAYS_EXTENSION_MODAL = 'seven_days_extension_modal';
export const FREE_FOREVER_INDEX_MODAL = 'free_forever_index_modal';
export const FREE_FOREVER_WELCOME_MODAL = 'free_forever_welcome_modal';
export const FREE_FOREVER_THANKING_MODAL = 'free_forever_thanking_modal';
export const TWO_FOR_ONE_INDEX_MODAL = 'two_for_one_index_modal';
export const TWO_FOR_ONE_EMAIL_MODAL = 'two_for_one_email_modal';
export const DEVICE_SELECTION_MODAL = 'device_selection_modal';
export const FREE_FOREVER_PLAN_DETAILS_MODAL =
  'free_forever_plan_details_modal';
export const PREMIUM_TO_FREE_FOREVER_MODAL = 'premium_to_free_forever_modal';
export const MANAGE_SUBSCRIPTION_MODAL = 'manage_subscription_modal';

export const PLAN_STATUS = {
  IN_TRIAL: 'in_trial',
  TRIAL_END: 'trial_end',
  EXTENDED_TRIAL_END: 'extended_trial_end',
  IN_PREMIUM: 'in_premium',
  IN_FREE_FOREVER: 'in_free_forever',
};
export const PLAN_IDS = {
  PREMIUM_ANNUAL: 'pay_now_annual_subscription',
  PREMIUM_ANNUAL_WITH_250GB: 'pay_now_annual_subscription_250_gb',
  PREMIUM_ANNUAL_WITH_500GB: 'pay_now_annual_subscription_500_gb',
  PREMIUM_ANNUAL_WITH_1TB: 'pay_now_annual_subscription_1_tb',
  PREMIUM_MONTHLY: 'premium_monthly',
  PREMIUM_MONTHLY_WITH_250GB: 'premium_monthly_250_gb',
  PREMIUM_MONTHLY_WITH_500GB: 'premium_monthly_500_gb',
  PREMIUM_MONTHLY_WITH_1TB: 'premium_monthly_1_tb',
  FREE_FOREVER: 'sp_free',
  TWO_FOR_ONE: '2_for_1_annual_subscription_discount',
  PREMIUM_ANNUAL_WITH_DISCOUNT: 'pay_now_annual_subscription_discount',
  PREMIUM_MONTHLY_WITH_DISCOUNT: 'pay_now_monthly_subscription_discount',
  PREMIUM_ANNUAL_WITH_DISCOUNT_30: 'annual_subscription_discount_30',
  SEVEN_DAY_PREMIUM_TRIAL: 'free_7_day_premium_trial',
  PREMIUM_ANNUAL_WITH_TRIAL: 'premium_annual_with_trial',
};
export const CURRENT_PLAN_STATUS = {
  ACTIVE: 'Active',
  CANCELLED: 'Cancelled',
  CHANGED: 'Changed',
  PLAN_IN_GRACE_PERIOD: 'plan_in_grace_period',
};

export const VALID_PAYMENT_PLATFORMS = ['web', 'chargebee'];
export const APPLE_PAYMENT_PLATFORMS = ['ios', 'apple'];
export const GOOGLE_PAYMENT_PLATFORMS = ['google'];
