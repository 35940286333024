const actions = {
  SET_ACTIVE_TAB_WIDTH: 'SET_ACTIVE_TAB_WIDTH',
  UPDATE_LANGUAGE: 'UPDATE_LANGUAGE',
  TEMP0RARY_ACTION: 'TEMP0RARY_ACTION',
  TEMP0RARY_ACTION_SUCCESS: 'TEMP0RARY_ACTION_SUCCESS',
  SET_SETTINGS_NAVIGATION: 'SET_SETTINGS_NAVIGATION',
  GET_PLANS_PAGE_LIST: 'GET_PLANS_PAGE_LIST',
  GET_PLANS_PAGE_LIST_SUCCESS: 'GET_PLANS_PAGE_LIST_SUCCESS',
  GET_PLANS_PAGE_LIST_FAILURE: 'GET_PLANS_PAGE_LIST_FAILURE',
};

export default actions;
